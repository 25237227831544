import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SoBanner from 'components/SoBanner';

import { ContactProps } from './models';

import 'styles/main.scss';
import './Contact.scss';

const Contact: FC<{ data: ContactProps }> = ({
  data: {
    contact: { pageLogo, urls, seo, content },
    footer,
    header,
    siteSettings,
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent>
      {header && <Header data={header} settings={siteSettings} alterlogo={pageLogo} />}
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />

      <div className="mx-auto section-container contact">
        {content?.map((data, index) => (
          <SoBanner data={data} index={index} />
        ))}
      </div>
      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $lang: String) {
    contact(url: { eq: $url }) {
      content {
        title
        subtitle
        variant
        containerText
        legalText
        pageSize
        containerImage {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1920)
            }
          }
        }
        imagePage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        imageButtonPopup {
          url
          altText
        }
        extraClass
        showPager
        showThumbs
        showArrows
        showStatus
        infiniteLoop
        items {
          variant
          extraClass
          legalText
          bannerText
          buttonType
          button {
            url
            icon
            name
            published
            udi
            queryString
          }
          imageDesktop {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 1920)
              }
            }
          }
          imageMobile {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
          imageProduct {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
          imageRecipe {
            altText
            desktop {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 768)
              }
            }
          }
        }
      }
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      pageLogo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        name
        url
        queryString
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default Contact;
